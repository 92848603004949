import * as React from "react";
import { SideNavigation, Button } from "@amzn/awsui-components-react";
import { navigationItems } from "../data/constData.js";

import "../styles/navigation.css";

export const Navigation = props => {
  const lastUpdated = props.timeStamp && props.timeStamp.toLocaleString();

  return (
    <div>
      <SideNavigation
        header={{ text: "Commerce Platform", href: "#/" }}
        items={navigationItems}
        activeHref="#/"
      />
      <div className="side-navigation-additions">
        <p>Last Updated:</p>
        <p>
          <strong>{lastUpdated}</strong>
        </p>
        <br />
        <Button
          iconAlign="right"
          iconName="add-plus"
          className="create-team-button"
          onClick={props.handleShowNewTeamModal}
        >
          Add Team
        </Button>
      </div>
    </div>
  );
};

export default Navigation;
