import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Dashboard from "./components/Dashboard";

import "./styles/header.css";

class App extends React.Component {
  render() {
    return (
      <div>
        <header>
          <ul className="menu-list">
            <li className="selected">
              <a href="/">AWS CP Oncall</a>
            </li>
          </ul>
        </header>
        <Router>
          <div>
            <Route exact path="/" component={Dashboard} />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
