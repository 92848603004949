import React, { useState } from "react";
import {
  Modal,
  FormField,
  Input,
  Button,
  Textarea,
  Spinner,
  Icon,
  Link
} from "@amzn/awsui-components-react";
import { MAX_CHAR, SUCCESS_MESSAGE } from "../data/constData.js";
import config from "../data/config";

import "../styles/pageModal.css";
import OncallToolServiceProxy from "../proxies/OncallToolServiceProxy";

const PageModal = ({ showModal, pageModalHandler, toAddress, alias }) => {
  const [subject, setSubject] = useState("");
  const [textBody, setTextBody] = useState("");
  const [textBodyLengthInvalid, setTextBodyLengthInvalid] = useState(false);
  const [subjectLengthInvalid, setSubjectLengthInvalid] = useState(false);
  const [pageResponseMessage, setPageResponseMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const charLeft = MAX_CHAR - textBody.length;

  const handleTextBodyInputChange = e => {
    const { value } = e.detail;
    setTextBody(value);
    setTextBodyLengthInvalid(value.length > MAX_CHAR);
  };

  const handleSubjectInputChange = e => {
    const { value } = e.detail;
    setSubject(value);
    setSubjectLengthInvalid(value.length > MAX_CHAR);
  };

  const handleSendPage = async () => {
    const request = {
      alias,
      toAddress,
      subject,
      textBody
    };
    setLoading(true);

    try {
      const response = await OncallToolServiceProxy.sendPage(request);
      await setPageResponseMessage(response.pageResponseMessage);
      setLoading(false);
    } catch (e) {
      await setPageResponseMessage(
        "Error occurred, page was not sent successfully."
      );
      setLoading(false);
    }
  };

  const invalidPageParameters =
    textBody.length < 1 ||
    subject.length < 1 ||
    textBodyLengthInvalid ||
    subjectLengthInvalid;

  const primaryFooterContent = (
    <div className="page-modal-button-container">
      <Button
        onClick={handleSendPage}
        variant="primary"
        disabled={invalidPageParameters}
      >
        Send Page
      </Button>
      <Button onClick={pageModalHandler} variant="normal">
        Cancel
      </Button>
    </div>
  );

  const sentPageFooterContent = (
    <div className="page-modal-button-container">
      <Button onClick={e => setPageResponseMessage(null)} variant="normal">
        Send another page?
      </Button>
      <Button onClick={pageModalHandler} variant="normal">
        Close
      </Button>
    </div>
  );

  const pageInputsContent = (
    <div>
      <FormField label="Send To:" className="page-form-field">
        <Input type="text" name="toAddress" value={toAddress} disabled={true} />
      </FormField>
      <br />
      <FormField label="Subject" className="page-form-field">
        <Input
          className="page-input"
          type="text"
          name="subject"
          placeholder="Subject..."
          value={subject}
          onChange={handleSubjectInputChange}
          invalid={subjectLengthInvalid}
        />
      </FormField>
      <br />
      <FormField
        label="Message"
        constraintText={`Max ${MAX_CHAR} characters. Remaining: ${charLeft}`}
        className="page-form-field"
      >
        <Textarea
          type="text"
          name="textBody"
          placeholder="Message..."
          value={textBody}
          onChange={handleTextBodyInputChange}
          invalid={textBodyLengthInvalid}
        />
      </FormField>
    </div>
  );

  const successIndicator =
    pageResponseMessage && pageResponseMessage === SUCCESS_MESSAGE;

  const responseMessageContent = (
    <div className="awsui-util-t-c">
      <Icon
        name={successIndicator ? "status-positive" : "status-negative"}
        variant={successIndicator ? "success" : "error"}
      />
      <span> </span>
      {pageResponseMessage}
      {!successIndicator && (
        <div>
          Please use{" "}
          <Link
            external
            externalIconAriaLabel="Opens in a new tab"
            href={config.amazonPaging}
            target="_blank"
          >
            Amazon Paging
          </Link>{" "}
          to send a page.
        </div>
      )}
    </div>
  );

  const spinnerContent = (
    <div className="awsui-util-t-c">
      <Spinner />
    </div>
  );

  const footerContent = pageResponseMessage
    ? sentPageFooterContent
    : primaryFooterContent;

  return (
    <div className="page-modal">
      <div className="awsui">
        <Modal
          visible={showModal}
          onDismiss={pageModalHandler}
          size="medium"
          expandToFit={true}
          footer={!loading && footerContent}
          header="Send Page"
        >
          {!pageResponseMessage && !loading && pageInputsContent}

          {pageResponseMessage && !loading && responseMessageContent}

          {loading && spinnerContent}
        </Modal>
      </div>
    </div>
  );
};

export default PageModal;
