import { PAGE_EMAIL_BODY, PAGE_EMAIL_SUBJECT } from "../../data/constData";
import config from "../../data/config";

export const generateEmailLink = alias => {
  return `mailto:page-${alias}@amazon.com?subject=${PAGE_EMAIL_SUBJECT}&body=${PAGE_EMAIL_BODY}`;
};

export const generateTeamEmailLink = teamPageAlias => {
  return `mailto:${teamPageAlias}@amazon.com?subject=${PAGE_EMAIL_SUBJECT}&body=${PAGE_EMAIL_BODY}`;
};

export const generateTeamPageAddress = teamPageAlias =>
  `${teamPageAlias}@amazon.com`;

export const generateAliasPageAddress = alias => `page-${alias}@amazon.com`;

export const generatePhonetoolLink = alias => {
  return config.phoneToolURI + alias;
};

export const generateChimeLink = alias => {
  return config.chimeBaseURI + alias + "@amazon.com";
};

export const generateSlackLink = alias => {
  return config.slackBaseURI + alias;
};

export const generateImageLink = alias => {
  return config.badgePhotoURI + alias;
};

export const generateOncallLink = team => {
  return `https://oncall.corp.amazon.com/#/view/${team}`;
};

export const generateSIMCreateLink = (category, type, item, group) => {
  return (
    config.simTicketBaseURI +
    `/create/options?title=Include+a+meaningful+description&description=Please+include+as+much+information+as+possible.&ticket_type=Regular+Ticket&severity=THREE&category=${category}&type=${type}&item=${item}&group=${group}`
  );
};

export const generateTTCreateLink = (category, type, item) => {
  return (
    config.ttTicketBaseURI +
    `/new?ticket_type=Regular+Ticket&prev_qid=0&impact=3&cti-finder-type=keyword&category=${category}&type=${type}&item=${item}&case_type=Trouble+Ticket&short_description=Include+a+meaningful+description&details=Please+include+as+much+information+as+possible.`
  );
};

export const generateAllSIMTickets = group => {
  return (
    config.simTicketBaseURI +
    `/issues/?q=extensions.tt.status%3A%28Assigned%20OR%20Researching%20OR%20%22Work%20In%20Progress%22%20OR%20Pending%29%20AND%20extensions.tt.assignedGroup%3A"${group}"`
  );
};

export const generateAllTTTickets = group => {
  return (
    config.ttTicketBaseURI +
    `/search?search=1&status=Assigned%3BResearching%3BWork+In+Progress%3BPending&assigned_group=${group}`
  );
};

export const generateDADLink = managerAlias => {
  return `https://www.prod.deployment-analysis.aws.a2z.com/user/${managerAlias}`;
};

export const generatePipelinesLink = managerAlias => {
  return `https://pipelines.amazon.com/whos_on_pipelines/${managerAlias}`;
};

export const generateSASLink = managerAlias => {
  return `https://sas.corp.amazon.com/v2/summary/all/${managerAlias}?campaignId=all`;
};

export const generatePVRELink = managerAlias => {
  return `https://security-dashboard.aws.a2z.com/dashboards/permalink/patching?user=${managerAlias}`;
};
