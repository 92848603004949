import * as React from "react";
import { connect } from "react-redux";
import { Table, Icon } from "@amzn/awsui-components-react";

import config from "../data/config.json";
import { oncallTableColumnDefinitions } from "../data/oncallTableColumnDefinitions.jsx";

import "../styles/onCallTable.css";

class OncallTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oncallDataToRender: [],
      currentSecondaries: {},
      tableOpen: true
    };
    this.handleModal = this.handleModal.bind(this);
    this.handleShowTeamInfoModal = this.handleShowTeamInfoModal.bind(this);
    this.handleTeamInfoModalForSecondaries =
      this.handleTeamInfoModalForSecondaries.bind(this);
    this.handleConfirmationModal = this.handleConfirmationModal.bind(this);
    this.handleSubmitTeamUpdate = this.handleSubmitTeamUpdate.bind(this);
    this.handleTableOpen = this.handleTableOpen.bind(this);
    this.handlePageModal = this.handlePageModal.bind(this);
    this.handlePageModalForSecondaries =
      this.handlePageModalForSecondaries.bind(this);
  }

  componentDidMount() {
    this.setState({
      oncallDataToRender: this.props.oncallData,
      currentSecondaries: this.props.applicationState.secondaries
    });
  }

  componentDidUpdate() {
    if (
      this.state.oncallDataToRender !== this.props.oncallData ||
      this.state.currentSecondaries !== this.props.applicationState.secondaries
    ) {
      this.setState({
        oncallDataToRender: this.props.oncallData,
        currentSecondaries: this.props.applicationState.secondaries
      });
    }
  }

  handleTableOpen(e) {
    this.setState(prevState => ({ tableOpen: !prevState.tableOpen }));
  }

  handleModal(team) {
    this.props.handleModal(team);
  }

  handleTeamInfoModalForSecondaries(teamData) {
    this.props.handleTeamInfoModalForSecondaries(teamData);
  }

  handleShowTeamInfoModal(teamData) {
    this.props.handleShowTeamInfoModal(teamData);
  }

  handleConfirmationModal() {
    this.props.handleConfirmationModal();
  }

  handlePageModal(teamId) {
    this.props.handlePageModal(teamId);
  }

  handlePageModalForSecondaries(teamId, teamData) {
    this.props.handlePageModalForSecondaries(teamId, teamData);
  }

  handleSubmitTeamUpdate(request) {
    this.props.handleSubmitTeamUpdate(request);
  }

  render() {
    const idForNav = this.props.oncallGroup
      ? this.props.oncallGroup.replace(" ", "-").toLowerCase()
      : "";
    const orgLink = this.props.oncallGroup
      ? this.props.oncallGroup.toUpperCase().split(" ").join("_")
      : "";

    let filteredTeams = null;

    if (this.props.oncallGroup) {
      filteredTeams = this.state.oncallDataToRender.filter(
        team => !team.isSecondary
      );
    } else {
      filteredTeams = this.state.oncallDataToRender;
    }

    const renderHeaderContent = () => {
      const orgName = (
        <div className="awsui-util-d-ib">
          {config[orgLink] ? (
            <a href={config[orgLink]} target="_blank" rel="noreferrer">
              <h2
                className="pillar-section-header"
                onClick={proxy => proxy.stopPropagation()}
              >
                {this.props.oncallGroup}
              </h2>
            </a>
          ) : (
            <h2 className="pillar-section-header">{this.props.oncallGroup}</h2>
          )}
        </div>
      );
      const openIcon = (
        <div className="awsui-util-d-ib open-icon">
          <Icon
            name={
              this.state.tableOpen ? "caret-down-filled" : "caret-right-filled"
            }
          />
        </div>
      );
      const orgHeaderNameAndIcon = (
        <div onClick={this.handleTableOpen}>
          <span className="org-section-link">
            {openIcon}
            {orgName}
          </span>
        </div>
      );
      return orgHeaderNameAndIcon;
    };

    return (
      <div className="awsui oncall-table">
        <Table
          id={idForNav}
          loadingText="Loading resources"
          columnDefinitions={
            this.state.tableOpen
              ? oncallTableColumnDefinitions(
                  this.handleModal,
                  this.handleShowTeamInfoModal,
                  this.handlePageModal,
                  this.state.currentSecondaries,
                  new Date()
                )
              : []
          }
          items={this.state.tableOpen ? filteredTeams : []}
          header={renderHeaderContent()}
          wrapLines
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  applicationState: state.oncallDataReducer
});

export default connect(mapStateToProps, null)(OncallTable);
