import * as React from "react";
import { connect } from "react-redux";
import {
  AppLayout,
  Spinner,
  Table,
  Button,
  Modal
} from "@amzn/awsui-components-react";
import Navigation from "./Navigation";
import OncallTable from "./OncallTable";
import TeamInfoModal from "./TeamInfoModal";
import ConfirmationModal from "./ConfirmationModal";
import PageModal from "./PageModal";

import {
  fetchOncallDataRequest,
  createOncallTeamRequest,
  updateOncallTeamRequest,
  updateSecondaryOncallTeamRequest,
  deleteOncallTeamRequest
} from "../actions/oncallAction";

import { oncallTableColumnDefinitions } from "../data/oncallTableColumnDefinitions.jsx";

import { orgGroups } from "../data/constData";

import "../styles/index.css";
import { setAlias } from "../actions/setAlias";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oncallData: [],
      initialLoadDone: false,
      user: "",
      loadDone: false,
      teams: [],
      timeStamp: "",
      selectedGroup: "AWS Commerce Platform",
      refreshRate: 60000,
      showNewTeamModal: false,
      showNewTeamConfirmationModal: false,
      showModal: [],
      showTeamInfoModal: false,
      teamToShowInModal: [],
      secondaryTeamToShow: [],
      showConfirmationModal: false,
      tableOpen: true,
      showPageModal: [],
      secondaryDataToShow: [],
      secondaryTeamToStore: [],
      currentSecondaries: {}
    };

    this.renewData = this.renewData.bind(this);
    this.handleShowNewTeamModal = this.handleShowNewTeamModal.bind(this);
    this.handleNewTeamConfirmationModal =
      this.handleNewTeamConfirmationModal.bind(this);
    this.handleSubmitTeamRequest = this.handleSubmitTeamRequest.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleShowTeamInfoModal = this.handleShowTeamInfoModal.bind(this);
    this.handleTeamInfoModalForSecondaries =
      this.handleTeamInfoModalForSecondaries.bind(this);
    this.handleConfirmationModal = this.handleConfirmationModal.bind(this);
    this.handleSubmitTeamUpdate = this.handleSubmitTeamUpdate.bind(this);
    this.handlePageModal = this.handlePageModal.bind(this);
    this.handlePageModalForSecondaries =
      this.handlePageModalForSecondaries.bind(this);
    this.handleDeleteTeam = this.handleDeleteTeam.bind(this);
  }

  componentDidMount() {
    this.props.setAlias();
    this.props.fetchOncallDataRequest();
    this.timerID = setInterval(() => this.renewData(), this.state.refreshRate);
    this.setState({
      timeStamp: new Date(),
      currentSecondaries: this.props.applicationState.secondaries
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  componentDidUpdate() {
    if (
      this.state.currentSecondaries !== this.props.applicationState.secondaries
    ) {
      this.setState({
        currentSecondaries: this.props.applicationState.secondaries
      });
    }
  }

  renewData() {
    this.props.fetchOncallDataRequest();
    this.setState({ timeStamp: new Date() });
  }

  handleShowNewTeamModal() {
    this.setState(prevState => ({
      showNewTeamModal: !prevState.showNewTeamModal
    }));
  }

  handleNewTeamConfirmationModal() {
    this.setState({ showNewTeamConfirmationModal: false });
  }

  handleSubmitTeamRequest(request) {
    this.setState({
      showNewTeamModal: false,
      showNewTeamConfirmationModal: true
    });
    this.props.createOncallTeamRequest(request);
  }

  handleModal(team) {
    if (this.state.showModal.length > 0) {
      return this.setState({ showModal: [] });
    } else {
      this.setState({
        showModal: [team],
        secondaryDataToShow: [this.state.currentSecondaries[team]]
      });
    }
  }

  handleTeamInfoModalForSecondaries(teamData) {
    this.setState(prevState => ({
      showTeamInfoModal: !prevState.showTeamInfoModal
    }));
    if (this.state.teamToShowInModal.length > 0) {
      this.setState({
        teamToShowInModal: [],
        showModal: [teamData.primaryOncallAlias],
        secondaryDataToShow: this.state.secondaryTeamToStore,
        secondaryTeamToStore: []
      });
    } else {
      this.setState({
        teamToShowInModal: [teamData],
        showModal: [],
        secondaryTeamToStore: [teamData]
      });
    }
  }

  handleShowTeamInfoModal(teamData) {
    this.setState(prevState => ({
      showTeamInfoModal: !prevState.showTeamInfoModal
    }));
    if (this.state.teamToShowInModal.length > 0) {
      this.setState({ teamToShowInModal: [] });
    } else {
      this.setState({ teamToShowInModal: [teamData] });
    }
  }

  handleConfirmationModal() {
    this.setState({ showConfirmationModal: false });
  }

  handlePageModal(teamId) {
    if (this.state.showPageModal.length > 0) {
      this.setState({ showPageModal: [] });
    } else {
      this.setState({ showPageModal: [teamId] });
    }
  }

  handlePageModalForSecondaries(teamId, teamData) {
    if (this.state.showPageModal.length > 0) {
      this.setState({
        showPageModal: [],
        showModal: [this.state.secondaryTeamToStore[0].primaryOncallAlias],
        secondaryDataToShow: this.state.secondaryTeamToStore,
        secondaryTeamToStore: []
      });
    } else {
      this.setState({
        showPageModal: [teamId],
        showModal: [],
        secondaryTeamToStore: [teamData]
      });
    }
  }

  handleSubmitTeamUpdate(request) {
    if (
      request.isSecondary ||
      this.props.applicationState.secondaries[request.primaryOncallAlias]
    ) {
      this.props.updateSecondaryOncallTeamRequest(request);
      this.setState({
        showTeamModal: [],
        teamToShowInModal: [],
        showTeamInfoModal: false,
        showModal: []
      });
    } else {
      this.props.updateOncallTeamRequest(request);
      this.setState({ teamToShowInModal: [], showTeamInfoModal: false });
    }
  }

  async handleDeleteTeam(request) {
    this.setState({
      teamToShowInModal: [],
      showTeamInfoModal: false
    });
    await this.props.deleteOncallTeamRequest(request);
    window.location.reload(false);
  }

  render() {
    const teamInfoModalContent = (
      <TeamInfoModal
        onClickHandler={this.handleShowNewTeamModal}
        onComplete={this.handleSubmitTeamRequest}
        show={this.state.showNewTeamModal}
      />
    );
    const confirmationModalContent = (
      <ConfirmationModal
        show={this.state.showNewTeamConfirmationModal}
        update={false}
        onClickHandler={this.handleNewTeamConfirmationModal}
      />
    );
    const oncallTeamsContent = () => {
      if (
        this.props.applicationState.oncallDataToDisplay &&
        this.props.applicationState.oncallDataToDisplay.length > 0
      ) {
        return this.props.applicationState.oncallDataToDisplay.map(
          (org, index) => {
            const sortedTeam = Object.keys(org)
              .map(item => org[item])
              .sort((a, b) => {
                if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
                  return -1;
                }
                if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
            return (
              <OncallTable
                oncallGroup={
                  orgGroups[this.props.applicationState.selectedProductGroup][
                    index
                  ]
                }
                oncallData={sortedTeam}
                key={index}
                handleTeamInfoModalForSecondaries={
                  this.handleTeamInfoModalForSecondaries
                }
                handleShowTeamInfoModal={this.handleShowTeamInfoModal}
                handleConfirmationModa={this.handleConfirmationModal}
                handlePageModal={this.handlePageModal}
                handlePageModalForSecondaries={
                  this.handlePageModalForSecondaries
                }
                handleModal={this.handleModal}
                handleSubmitTeamUpdate={this.handleSubmitTeamUpdate}
              />
            );
          }
        );
      } else {
        return <Spinner size="large" className="spinner-position"></Spinner>;
      }
    };

    const displayContent = () => {
      if (this.state.showNewTeamModal) {
        return teamInfoModalContent;
      } else if (this.state.showNewTeamConfirmationModal) {
        return confirmationModalContent;
      } else {
        return oncallTeamsContent();
      }
    };

    return (
      <div className="awsui">
        <AppLayout
          className="awsui-util-no-gutters"
          loading={!this.state.loadDone}
          content={displayContent()}
          navigation={
            <Navigation
              timeStamp={this.state.timeStamp}
              handleShowNewTeamModal={this.handleShowNewTeamModal}
            />
          }
          toolsHide={true}
        />
        <TeamInfoModal
          show={this.state.showTeamInfoModal}
          teamInfo={this.state.teamToShowInModal[0]}
          update={true}
          onClickHandler={
            this.state.secondaryTeamToStore.length > 0
              ? this.handleTeamInfoModalForSecondaries
              : this.handleShowTeamInfoModal
          }
          onComplete={this.handleSubmitTeamUpdate}
          onDelete={this.handleDeleteTeam}
        />
        <PageModal
          showModal={this.state.showPageModal.length > 0}
          toAddress={
            this.state.showPageModal.length > 0
              ? this.state.showPageModal[0]
              : ""
          }
          pageModalHandler={
            this.state.secondaryTeamToStore.length > 0
              ? this.handlePageModalForSecondaries
              : this.handlePageModal
          }
          alias={this.props.applicationState.requester}
        />
        <Modal
          visible={this.state.showModal.length > 0}
          header={`Secondary Oncall for ${
            this.state.showModal.length > 0 ? this.state.showModal[0] : ""
          }`}
          size="max"
          onDismiss={this.handleModal}
          footer={
            <span className="awsui-util-f-r">
              <Button variant="primary" onClick={this.handleModal}>
                Close
              </Button>
            </span>
          }
        >
          <Table
            loadingText="Loading resources"
            columnDefinitions={
              this.state.tableOpen
                ? oncallTableColumnDefinitions(
                    this.handleModal,
                    this.handleTeamInfoModalForSecondaries,
                    this.handlePageModalForSecondaries,
                    this.props.applicationState.secondaries,
                    new Date()
                  )
                : []
            }
            items={this.state.tableOpen ? this.state.secondaryDataToShow : []}
            wrapLines
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  applicationState: state.oncallDataReducer
});

const mapDispatchToProps = dispatch => ({
  fetchOncallDataRequest: () => dispatch(fetchOncallDataRequest()),
  createOncallTeamRequest: request =>
    dispatch(createOncallTeamRequest(request)),
  updateOncallTeamRequest: request =>
    dispatch(updateOncallTeamRequest(request)),
  updateSecondaryOncallTeamRequest: request =>
    dispatch(updateSecondaryOncallTeamRequest(request)),
  deleteOncallTeamRequest: request =>
    dispatch(deleteOncallTeamRequest(request)),
  setAlias: () => dispatch(setAlias())
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
