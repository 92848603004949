import { getAPIClient } from "../clients/identityClient";

let alias;
const GET_IDENTITY_ENDPOINT = "/identity";

export async function getIdentityAPICall() {
  if (!alias) {
    const client = await getAPIClient();
    alias = (await client.get(GET_IDENTITY_ENDPOINT)).data;
  }
  return alias;
}
