import config from "./configOrigin.jsx";
import links from "./config.json";


export const AWS_BILLING = "AWS Billing";
export const AWS_FRAUD = "AWS Fraud";
export const AWS_INSIGHTS = "AWS Insights";
export const AWS_PAYMENTS = "AWS Payments";
export const MISC = "Misc";
export const PRET_IE2 = "PRET-IE2";
export const AWS_CP_GM_DELEGATES = "AWS-CP-GM-DELEGATES";
export const ADC_OPERATIONS = "ADC Operations";


export const IDENTITY = "identity";
export const DISPLAY_NAME = "displayName";
export const ORG = "org";
export const PRODUCT_GROUP = "productGroup";
export const SERVICES = "services";
export const CATEGORY = "category";
export const TYPE = "type";
export const ITEM = "item";
export const RESOLVER_GROUP = "resolverGroup";
export const MANAGER_ALIAS = "managerAlias";
export const PRIMARY_ONCALL_ALIAS = "primaryOncallAlias";
export const IS_SECONDARY = "isSecondary";
export const SIM = "sim";

export const identityTypes = [
  {
    id: "teamName",
    label: "teamName",
    value: "teamName",
    description: ""
  },
  {
    id: "alias",
    label: "alias",
    value: "alias",
    description: ""
  }
];

export const yesNoOptions = [
  {
    id: "yes",
    label: "Yes",
    value: true,
    description: ""
  },
  {
    id: "no",
    label: "No",
    value: false,
    description: ""
  }
];

export const simTTOptions = [
  {
    id: SIM,
    label: "SIM",
    value: true,
    description: ""
  },
  {
    id: "tt",
    label: "TT",
    value: false,
    description: ""
  }
];

export const productGroupOptions = [
  {
    id: "AWS Commerce Platform",
    label: "AWS Commerce Platform",
    value: "AWS Commerce Platform",
    description: ""
  }
];

export const orgs = {
  "AWS Commerce Platform": [
    {
      id: ADC_OPERATIONS,
      label: ADC_OPERATIONS,
      value: ADC_OPERATIONS,
      description: ""
    },
    {
      id: AWS_CP_GM_DELEGATES,
      label: AWS_CP_GM_DELEGATES,
      value: AWS_CP_GM_DELEGATES,
      description: ""
    },
    {
      id: AWS_BILLING,
      label: AWS_BILLING,
      value: AWS_BILLING,
      description: ""
    },
    {
      id: AWS_FRAUD,
      label: AWS_FRAUD,
      value: AWS_FRAUD,
      description: ""
    },
    {
      id: AWS_INSIGHTS,
      label: AWS_INSIGHTS,
      value: AWS_INSIGHTS,
      description: ""
    },
    {
      id: AWS_PAYMENTS,
      label: AWS_PAYMENTS,
      value: AWS_PAYMENTS,
      description: ""
    },
    {
      id: MISC,
      label: MISC,
      value: MISC,
      description: ""
    },
    {
      id: PRET_IE2,
      label: PRET_IE2,
      value: PRET_IE2,
      description: ""
    },
  ]
};

export const oncallLink = "https://oncall.corp.amazon.com";

export const PAGE_EMAIL_SUBJECT = "Include A Subject For Page";
export const PAGE_EMAIL_BODY = "Include a reason for this page...";

export const navigationItems = [
  { type: "link", text: ADC_OPERATIONS, href: "#adc-operations" },
  { type: "link", text: AWS_BILLING, href: "#aws-billing" },
  { type: "link", text: AWS_CP_GM_DELEGATES, href: "#aws-cp-gm-delegates" },
  { type: "link", text: AWS_FRAUD, href: "#aws-fraud" },
  { type: "link", text: AWS_INSIGHTS, href: "#aws-insights" },
  { type: "link", text: AWS_PAYMENTS, href: "#aws-payments" },
  { type: "link", text: PRET_IE2, href: "#pret-ie2" },
  { type: "link", text: MISC, href: "#misc" },
  
  { type: "divider" },
  {
    type: "section",
    text: "Oncall Tool Resources",
    items: [
      {
        type: "link",
        text: "Getting Started",
        href: links.onboardingLink,
        external: true
      },
      {
        type: "link",
        text: "Feedback",
        href: links.feedbackLink,
        external: true
      }
    ]
  },
  { type: "divider" },
  {
    type: "section",
    text: "External Links",
    items: [
      {
        type: "link",
        text: "CP One",
        href: config.cpOne,
        external: true
      },
      {
        type: "link",
        text: "Bingo",
        href: config.bingo,
        external: true
      }
    ]
  },
  { type: "divider" }
];

export const pret = {};
export const billing = {};
export const fraud = {};
export const insights = {};
export const payments = {};
export const misc = {};
export const cp_gm_delegate = {};
export const adc_operations = {};

export const orgMap = {
  [AWS_BILLING]: billing,
  [AWS_CP_GM_DELEGATES]: cp_gm_delegate,
  [ADC_OPERATIONS]: adc_operations,
  [AWS_FRAUD]: fraud,
  [AWS_INSIGHTS]: insights,
  [AWS_PAYMENTS]: payments,
  [MISC]: misc,
  [PRET_IE2]: pret
};

export const secondaries = {};

export const productGroups = {
  "AWS Commerce Platform": [pret, billing, fraud, insights, payments, misc, cp_gm_delegate]
};

export const orgGroups = {
  "AWS Commerce Platform": [
    AWS_CP_GM_DELEGATES,
    PRET_IE2,
    ADC_OPERATIONS,
    AWS_BILLING,
    AWS_FRAUD,
    AWS_INSIGHTS,
    AWS_PAYMENTS,
    MISC
  ]
};

export const MAX_CHAR = 255;

export const SUCCESS_MESSAGE = "Page was sent successfully!";

export const requiredFields = [
  IDENTITY,
  DISPLAY_NAME,
  ORG,
  PRODUCT_GROUP,
  SERVICES,
  CATEGORY,
  TYPE,
  ITEM,
  RESOLVER_GROUP,
  MANAGER_ALIAS
];