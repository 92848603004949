import configSet from "./configSet.json";

var configOrigin = configSet.test;

if (window.location.origin.includes("alpha")) {
  configOrigin = configSet.alpha;
} else if (window.location.origin.includes("beta")) {
  configOrigin = configSet.beta;
} else if (window.location.origin.includes("gamma")) {
  configOrigin = configSet.gamma;
} else if (window.location.origin.includes("prod")) {
  configOrigin = configSet.prod;
}

export default configOrigin;