import { SET_ALIAS } from "./types";
import { getIdentityAPICall } from "../proxies/identityProxy";

export const setAlias = () => async dispatch => {
  const alias = await getIdentityAPICall();
    dispatch({
      type: SET_ALIAS,
      payload: alias
    });
  };
