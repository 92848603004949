import { AWSCPAtlasOncallToolServiceClientConfigFactory } from "@amzn/coral-config_a-wscpatlas-oncall-tool-service";
import {
  AWSCPAtlasOncallToolService,
  aWSCPAtlasOncallToolServiceSerdesInterceptor
} from "@amzn/coral_com-amazon-awscpatlasoncalltoolservice";
import { MidwayIdentityCredentialProvider } from "@amzn/midway-identity-credential-provider";
import {
  Aws4HmacSha256Interceptor,
  CoralClientFactory,
  CoralFetchHandler,
  CoralInterceptor,
  InterceptingHandler
} from "@amzn/ihm-js-coral-client";

import config from "../data/configOrigin";
import { map } from "rxjs/operators";

class TempFixCoralInterceptor extends CoralInterceptor {
  intercept(request, next) {
    return next.handle(
      this.addOmitCredentials(this.replaceNullBodyWithUndefined(request))
    );
  }

  /**
   * Temporary FIX: To avoid generating body for GET requests.
   * Ref 1: This is added here: https://code.amazon.com/packages/IhmJSCoralClient/blobs/3bdd1a1f5735f4c3c14350cd8273cf5fda6841ac/--/src/interceptors/coral-client-http-binding-interceptor.ts#L56
   * Ref 2: It is used here: https://code.amazon.com/packages/IhmJSCoralClient/blobs/95d6123041b0a66d6f1a940058849ca1adc25000/--/src/interceptors/aws4-hmac-sha256-interceptor.ts#L78
   */
  replaceNullBodyWithUndefined(request) {
    return request.clone({
      body: !request.body ? undefined : request.body
    });
  }

  /**
   * FIX: To avoid CORS access control errors when credential mode is `include`
   * In CoralRequest class, `credentials` are by default set to `included` if it is either undefined | null | empty string
   *  i. On construction
   *  ii. Everytime it is cloned
   *  https://code.amazon.com/packages/IhmJSCoralClient/blobs/2166c3ea992ea581bea925c309907f20b2cb7863/--/src/client/coral-request.ts#L59
   * Hence to avoid switching to `include` automatically, setting it to `omit`
   */
  addOmitCredentials(request) {
    return request.clone({
      credentials: "omit"
    });
  }
}

class ClientOverride extends AWSCPAtlasOncallToolService {
  getOncallTeams(request) {
    return super.getOncallTeams(request).pipe(
      map(response => {
        return {
          ...response
        };
      })
    );
  }

  postTeam(request) {
    return super.createTeamOncall(request).pipe(
      map(response => {
        return {
          ...response
        };
      })
    );
  }

  deleteTeamOncall(request) {
    return super.deleteTeamOncall(request).pipe(
      map(response => {
        return {
          ...response
        };
      })
    );
  }

  postTeamInfo(request) {
    return super.createTeamInfo(request).pipe(
      map(response => {
        return {
          ...response
        };
      })
    );
  }

  deleteTeamInfo(request) {
    return super.deleteTeamInfo(request).pipe(
      map(response => {
        return {
          ...response
        };
      })
    );
  }

  sendPage(request) {
    return super.sendPage(request).pipe(
      map(response => {
        return {
          ...response
        };
      })
    );
  }
}

const qualifier = config.qualifier;
const cognitoPoolId = config.oncallToolServiceCognitoIdentityPoolId;

const credentials = MidwayIdentityCredentialProvider.newProvider({
  ...AWSCPAtlasOncallToolServiceClientConfigFactory.getConfigForQualifier(
    qualifier
  ),
  midwayIdentity: {
    cognitoIdentityPoolId: cognitoPoolId
  }
});
const aws4HmacSha256Interceptor = new Aws4HmacSha256Interceptor(async () => {
  await credentials.getPromise();
  return {
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken
  };
});

export const AWSCPAtlasOncallToolServiceClient = new CoralClientFactory(
  [AWSCPAtlasOncallToolServiceClientConfigFactory],
  qualifier,
  new InterceptingHandler(new CoralFetchHandler(), [
    aWSCPAtlasOncallToolServiceSerdesInterceptor,
    new TempFixCoralInterceptor(),
    aws4HmacSha256Interceptor
  ])
).get(ClientOverride);
