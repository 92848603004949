const timeRemainingFinder = (date1, date2) => {
    const now = new Date(date1);
    const endTime = new Date(date2);
    const diffMs = (endTime - now); // milliseconds
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  
    return diffDays + " days, " + diffHrs + " hrs, " + diffMins + " min remaining";
  };
  
  export default timeRemainingFinder;
  