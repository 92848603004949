import {
  GET_ONCALL_TEAMS,
  DELETE_TEAM,
  CREATE_TEAM,
  UPDATE_TEAM,
  ERR_CATCH
} from "./types";
import OncallToolServiceProxy from "../proxies/OncallToolServiceProxy";

const getOncallTeams = oncalls => ({
  type: GET_ONCALL_TEAMS,
  payload: oncalls
});

const deleteOncallTeam = team => ({
  type: DELETE_TEAM,
  payload: team
});

const createOncallTeam = team => ({
  type: CREATE_TEAM,
  payload: team
});

const updateOncallTeam = team => ({
  type: UPDATE_TEAM,
  payload: team
});

const errCatch = err => ({
  type: ERR_CATCH,
  payload: err
});

export const fetchOncallDataRequest = () => async dispatch => {
  try {
    const teamsResponse = await OncallToolServiceProxy.getOncallTeams();
    return dispatch(getOncallTeams(teamsResponse));
  } catch (err) {
    return dispatch(errCatch(err));
  }
};

export const deleteOncallTeamRequest = request => async dispatch => {
  try {
    await OncallToolServiceProxy.deleteTeamInfo(request);
    const deleteOncallTeamResponse =
      await OncallToolServiceProxy.deleteTeamOncall(request);
    return dispatch(deleteOncallTeam(deleteOncallTeamResponse));
  } catch (err) {
    return dispatch(errCatch(err));
  }
};

export const updateOncallTeamRequest = request => async dispatch => {
  try {
    await OncallToolServiceProxy.postTeamInfo(request);
    const postOncallTeamResponse = await OncallToolServiceProxy.postTeam(
      request
    );
    return dispatch(updateOncallTeam(postOncallTeamResponse));
  } catch (err) {
    return dispatch(errCatch(err));
  }
};

export const updateSecondaryOncallTeamRequest = request => async dispatch => {
  try {
    await OncallToolServiceProxy.postTeamInfo(request);
    await OncallToolServiceProxy.postTeam(request);
    const teamsResponse = await OncallToolServiceProxy.getOncallTeams();
    return dispatch(getOncallTeams(teamsResponse));
  } catch (err) {
    return dispatch(errCatch(err));
  }
};

export const createOncallTeamRequest = request => async dispatch => {
  try {
    await OncallToolServiceProxy.postTeamInfo(request);
    const postOncallTeamResponse = await OncallToolServiceProxy.postTeam(
      request
    );
    return dispatch(createOncallTeam(postOncallTeamResponse));
  } catch (err) {
    return dispatch(errCatch(err));
  }
};
