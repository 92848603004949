import React from "react";

import { Button, Icon, Link } from "@amzn/awsui-components-react";

import timeRemainingFinder from "../components/helpers/timeRemainingFinder";
import {
  generatePhonetoolLink,
  generateChimeLink,
  generateSlackLink,
  generateImageLink,
  generateOncallLink,
  generateSIMCreateLink,
  generateTTCreateLink,
  generateAllSIMTickets,
  generateAllTTTickets,
  generateDADLink,
  generatePipelinesLink,
  generateAliasPageAddress,
  generateTeamPageAddress,
  generateSASLink,
  generatePVRELink
} from "../components/helpers/tableItemsHelper.js";

import "../styles/onCallTable.css";

export const oncallTableColumnDefinitions = (
  handleModal,
  handleShowTeamInfoModal,
  handlePageModal,
  secondaries,
  now
) => {
  const name = team => (
    <div className="team-name-container">
      <div>
        <div className="update-team-button team-display-name">
          <Button
            iconAlign="right"
            iconName="edit"
            className="update-team-button"
            onClick={() => handleShowTeamInfoModal(team)}
            variant="icon"
            id={team.identity + "," + team.identityType}
          ></Button>
        </div>
        <br />
        <div className="team-display-name">
          <Link href={generateOncallLink(team.identity)} target="_blank">
            {team.displayName}
          </Link>
        </div>
        <br />
        {team.teamPageAlias && (
          <div className="team-display-name">
            <Button
              iconAlign="right"
              iconName="notification"
              onClick={() =>
                handlePageModal(
                  generateTeamPageAddress(team.teamPageAlias),
                  team
                )
              }
            >
              Page Team
            </Button>
          </div>
        )}
        <br />
        {secondaries[team.identity] && (
          <div className="team-display-name">
            <h4>Secondary Oncall:</h4>
            <Link>
              <span onClick={() => handleModal(team.identity)}>
                {secondaries[team.identity].displayName}
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );

  const current = team => {
    const currAlias = team.currOncalls ? team.currOncalls[0] : "";
    return (
      <div className="alias-view">
        <div>
          <Link href={generatePhonetoolLink(currAlias)} target="_blank">
            <img src={generateImageLink(currAlias)} alt="" />
          </Link>
        </div>
        <div>
          <Link href={generatePhonetoolLink(currAlias)} target="_blank">
            {currAlias}@
          </Link>
        </div>
        <div>
          <Link href={generateSlackLink(currAlias)} target="_blank">
            Slack
          </Link>
          <span> | </span>
          <Link href={generateChimeLink(currAlias)} target="_blank">
            Chime
          </Link>
          <div>
            <Link>
              <span
                onClick={() =>
                  handlePageModal(generateAliasPageAddress(currAlias), team)
                }
              >
                Page <Icon name="notification" />
              </span>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const timeRemaining = team => {
    const timeRemaining = timeRemainingFinder(
      now,
      !team.currEnd || team.currEnd === "2100-01-01T00:00:00-08:00"
        ? now
        : team.currEnd
    );
    const shiftChange = team.currEnd ? new Date(team.currEnd) : now;
    return (
      <div className="time-remaining">
        <div className="awsui">
          <strong>{timeRemaining}</strong>
        </div>
        <br />
        <div className="shift-change">
          <p>Next Shift Change:</p>
          {shiftChange.toLocaleString()}
        </div>
      </div>
    );
  };

  const nextOnCall = team => {
    const nextAlias = team.currOncalls ? team.nextOncalls[0] : "";

    return (
      <div className="alias-view">
        <div>
          <Link href={generatePhonetoolLink(nextAlias)} target="_blank">
            <img src={generateImageLink(nextAlias)} alt="" />
          </Link>
        </div>
        <div className="awsui">
          <Link href={generatePhonetoolLink(nextAlias)} target="_blank">
            {nextAlias}@
          </Link>
        </div>
        <div>
          <Link href={generateSlackLink(nextAlias)} target="_blank">
            Slack
          </Link>
          <span> | </span>
          <Link href={generateChimeLink(nextAlias)} target="_blank">
            Chime
          </Link>
          <div>
            <Link>
              <span
                onClick={() =>
                  handlePageModal(generateAliasPageAddress(nextAlias), team)
                }
              >
                Page <Icon name="notification" />
              </span>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const createTicket = team => {
    const viewtickets = team.sim
      ? generateAllSIMTickets(team.resolverGroup)
      : generateAllTTTickets(team.resolverGroup);

    const ticketLink = team.sim
      ? generateSIMCreateLink(
          team.category,
          team.type,
          team.item,
          team.resolverGroup
        )
      : generateTTCreateLink(team.category, team.type, team.item);

    return (
      <div className="create-ticket">
        <div>
          <Link href={viewtickets} target="_blank">
            View Tickets
          </Link>
        </div>
        <div>—</div>
        <div>
          <Button
            iconName="add-plus"
            href={ticketLink}
            target="_blank"
            rel="noreferrer"
            iconAlign="right"
            wrapText={false}
          >
            Create Ticket
          </Button>
        </div>
      </div>
    );
  };

  const services = team => (
    <div className="services">
      {team ? team.services : ""}
      <br />
      <br />
      {team.managerAlias && (
        <div className="awsui-util-d-ib">
          <p>
            Manager:{" "}
            <Link
              href={generatePhonetoolLink(team.managerAlias)}
              target="_blank"
            >
              {team.managerAlias}@
            </Link>
            <span> | </span>
            <Link href={generateSlackLink(team.managerAlias)} target="_blank">
              Slack
            </Link>
            <span> | </span>
            <Link href={generateChimeLink(team.managerAlias)} target="_blank">
              Chime
            </Link>
            <span> | </span>
            <Link>
              <span
                onClick={() =>
                  handlePageModal(
                    generateAliasPageAddress(team.managerAlias),
                    team
                  )
                }
              >
                Page <Icon name="notification" />
              </span>
            </Link>
          </p>
        </div>
      )}
      <div>
        <p>
          <Link href={generatePipelinesLink(team.managerAlias)} target="_blank">
            Pipelines
          </Link>{" "}
          |{" "}
          <Link href={generateDADLink(team.managerAlias)} target="_blank">
            DAD
          </Link>{" "}
          |{" "}
          <Link href={generateSASLink(team.managerAlias)} target="_blank">
            SAS
          </Link>{" "}
          |{" "}
          <Link href={generatePVRELink(team.managerAlias)} target="_blank">
            PVRE
          </Link>{" "}
        </p>
      </div>
    </div>
  );

  return [
    {
      id: "variable",
      header: <div className="column-title">Team Name</div>,
      cell: item => name(item),
      maxWidth: 250
    },
    {
      id: "current",
      header: <div className="column-title">Current Oncall</div>,
      cell: item => current(item)
    },
    {
      id: "timeRemaining",
      header: <div className="column-title">Time Remaining</div>,
      cell: item => timeRemaining(item)
    },
    {
      id: "nextOnCall",
      header: <div className="column-title">Next Oncall</div>,
      cell: item => nextOnCall(item)
    },
    {
      id: "createTicket",
      header: <div className="column-title">Tickets</div>,
      cell: item => createTicket(item)
    },

    {
      id: "services",
      header: <div className="column-title">Services</div>,
      cell: item => services(item)
    }
  ];
};
