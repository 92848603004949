import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Modal,
  FormField,
  Input,
  Select,
  Button,
  Textarea,
  Icon
} from "@amzn/awsui-components-react";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import {
  fetchOncallDataRequest,
  deleteOncallTeamRequest
} from "../actions/oncallAction";

import {
  identityTypes,
  requiredFields,
  orgs,
  yesNoOptions,
  simTTOptions,
  productGroupOptions,
  DISPLAY_NAME,
  MANAGER_ALIAS,
  IDENTITY,
  PRODUCT_GROUP,
  ORG,
  SERVICES,
  CATEGORY,
  TYPE,
  ITEM,
  RESOLVER_GROUP,
  PRIMARY_ONCALL_ALIAS,
  SIM,
  IS_SECONDARY
} from "../data/constData";

import teamInfoImage from "../assets/oncall_identity_hint_image.png";

import "../styles/teamInfoModal.css";

class TeamInfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: null,
      identity: null,
      identityType: null,
      displayName: null,
      services: null,
      org: null,
      productGroup: null,
      category: null,
      type: null,
      item: null,
      resolverGroup: null,
      managerAlias: null,
      primaryOncallAlias: null,
      isSecondary: null,
      sim: null,
      identityInvalid: false,
      displayNameInvalid: false,
      orgInvalid: false,
      productGroupInvalid: false,
      servicesInvalid: false,
      categoryInvalid: false,
      typeInvalid: false,
      itemInvalid: false,
      resolverGroupInvalid: false,
      managerAliasInvalid: false,
      primaryOncallAliasInvalid: false,
      showDeleteModal: false,
      showThisModal: true,
      showInfoImageModal: false,
      errorText: {}
    };
    this.handleStateUpdate = this.handleStateUpdate.bind(this);
    this.findSim = this.findSim.bind(this);
    this.findIsSecondary = this.findIsSecondary.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectMenu = this.handleSelectMenu.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleSubmitTeamInfo = this.handleSubmitTeamInfo.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleDeleteTeam = this.handleDeleteTeam.bind(this);
    this.handleInfoImageModal = this.handleInfoImageModal.bind(this);
  }

  handleInfoImageModal() {
    this.setState(prevState => ({
      showInfoImageModal: !prevState.showInfoImageModal,
      showThisModal: prevState.showInfoImageModal
    }));
  }

  componentDidMount() {
    this.handleStateUpdate();
  }

  componentDidUpdate() {
    if (
      this.props.teamInfo &&
      this.props.teamInfo.identity !== this.state.identity
    ) {
      this.handleStateUpdate();
    }
  }

  handleStateUpdate() {
    return this.setState({
      active: true,
      identity: this.props.teamInfo ? this.props.teamInfo.identity : null,
      identityType: this.props.teamInfo
        ? this.props.teamInfo.identityType
        : identityTypes[0].id,
      displayName: this.props.teamInfo ? this.props.teamInfo.displayName : null,
      services: this.props.teamInfo ? this.props.teamInfo.services : null,
      org: this.props.teamInfo ? this.props.teamInfo.org : null,
      // ------------
      // Disabling productGroup to CP and category to AWS for this iteration.
      // ------------
      productGroup: "AWS Commerce Platform",
      category: this.props.teamInfo ? this.props.teamInfo.category : "AWS",
      type: this.props.teamInfo ? this.props.teamInfo.type : null,
      item: this.props.teamInfo ? this.props.teamInfo.item : null,
      resolverGroup: this.props.teamInfo
        ? this.props.teamInfo.resolverGroup
        : null,
      managerAlias: this.props.teamInfo
        ? this.props.teamInfo.managerAlias
        : null,
      primaryOncallAlias:
        this.props.teamInfo && this.props.teamInfo.primaryOncallAlias !== "null"
          ? this.props.teamInfo.primaryOncallAlias
          : null,
      isSecondary:
        this.props.teamInfo && this.props.teamInfo.isSecondary ? "yes" : "no",
      sim: !this.props.teamInfo || this.props.teamInfo.sim ? SIM : "tt"
    });
  }

  findSim() {
    return this.state.sim === SIM;
  }

  findIsSecondary() {
    return this.state.isSecondary === "yes";
  }

  handleInputChange(name, value) {
    this.setState({ [name]: value });
    this.setState(prevState => ({
      errorText: { ...prevState.errorText, [name]: null }
    }));
  }

  handleSelectMenu(name, selectedOption) {
    this.setState({ [name]: selectedOption, [[name] + "Invalid"]: false });
    this.setState(prevState => ({
      errorText: { ...prevState.errorText, [name]: null }
    }));
  }

  handleModal() {
    this.setState({ errorText: {} });
    if (this.props.teamInfo) {
      return this.props.onClickHandler(this.state.identity);
    }
    return this.props.onClickHandler();
  }

  handleSubmitTeamInfo() {
    if (
      !this.state.identity ||
      !this.state.displayName ||
      !this.state.org ||
      !this.state.productGroup ||
      (!this.state.primaryOncallAlias && this.state.isSecondary === "yes") ||
      !this.state.services ||
      !this.state.category ||
      !this.state.type ||
      !this.state.item ||
      !this.state.resolverGroup ||
      !this.state.managerAlias
    ) {
      requiredFields.forEach(field => {
        if (!this.state[field]) {
          this.setState(prevState => ({
            errorText: { ...prevState.errorText, [field]: "Required" }
          }));
        }
      });

      if (!this.state.primaryOncallAlias && this.state.isSecondary === "yes") {
        this.setState(prevState => ({
          errorText: {
            ...prevState.errorText,
            primaryOncallAlias: "Required"
          }
        }));
      }

      console.log(this.state);
      return;
    } else {
      const sim = this.findSim();
      const isSecondary = this.findIsSecondary();

      let request = null;

      if (this.props.update) {
        request = {
          ...this.props.teamInfo,
          active: this.state.active,
          identity: this.state.identity,
          identityType: this.state.identityType,
          displayName: this.state.displayName,
          services: this.state.services,
          productGroup: this.state.productGroup,
          org: this.state.org,
          category: this.state.category,
          type: this.state.type,
          item: this.state.item,
          resolverGroup: this.state.resolverGroup,
          managerAlias: this.state.managerAlias,
          primaryOncallAlias: this.state.primaryOncallAlias,
          isSecondary,
          sim
        };
      } else {
        request = {
          active: this.state.active,
          identity: this.state.identity,
          identityType: this.state.identityType,
          displayName: this.state.displayName,
          services: this.state.services,
          productGroup: this.state.productGroup,
          org: this.state.org,
          category: this.state.category,
          type: this.state.type,
          item: this.state.item,
          resolverGroup: this.state.resolverGroup,
          managerAlias: this.state.managerAlias,
          primaryOncallAlias:
            this.state.primaryOncallAlias !== ""
              ? this.state.primaryOncallAlias
              : null,
          isSecondary,
          sim
        };
      }
      this.props.onComplete(request);
    }
  }

  handleDeleteModal() {
    this.setState(prevState => ({
      showDeleteModal: !prevState.showDeleteModal,
      showThisModal: !prevState.showThisModal
    }));
  }

  handleDeleteTeam(request) {
    this.props.onDelete(request);
    this.setState({ showDeleteModal: false, showThisModal: false });
  }

  render() {
    return (
      <div className="awsui input-container">
        <Modal
          header={this.props.teamInfo ? "Update OnCall Team Info" : "Add Team"}
          visible={this.props.show && this.state.showThisModal}
          size="large"
          expandToFit={true}
          onDismiss={this.handleModal}
          footer={
            <div className="team-info-modal-buttons-container">
              <Button onClick={this.handleSubmitTeamInfo} variant="primary">
                {this.props.teamInfo ? "Update Team" : "Add Team"}
              </Button>
              <Button onClick={this.handleModal} variant="normal">
                Cancel
              </Button>
              {this.props.update && (
                <Button
                  className="delete-button"
                  onClick={this.handleDeleteModal}
                  variant="normal"
                >
                  Delete Team
                </Button>
              )}
            </div>
          }
        >
          <div>
            <div>
              <div className="inline-input half-width-input">
                <FormField
                  label="Team Name"
                  errorText={this.state.errorText[DISPLAY_NAME]}
                >
                  <Input
                    type="text"
                    name={DISPLAY_NAME}
                    value={this.state.displayName}
                    placeholder="Team Name"
                    onChange={({ detail }) =>
                      this.handleInputChange(DISPLAY_NAME, detail.value)
                    }
                  />
                </FormField>
              </div>
              <div className="inline-input half-width-input">
                <FormField
                  label="Team Manager Alias"
                  errorText={this.state.errorText[MANAGER_ALIAS]}
                >
                  <Input
                    type="text"
                    placeholder="Team Manager Alias"
                    name={MANAGER_ALIAS}
                    value={this.state.managerAlias}
                    onChange={({ detail }) =>
                      this.handleInputChange(MANAGER_ALIAS, detail.value)
                    }
                  />
                </FormField>
              </div>
            </div>
            <br />
            <div>
              <div className="inline-input half-width-input">
                <FormField
                  label="Oncall Identity"
                  errorText={this.state.errorText[IDENTITY]}
                  hintText={
                    <div onClick={this.handleInfoImageModal}>
                      <Icon
                        name="status-info"
                        variant="link"
                        onClick={this.showInfoModal}
                      ></Icon>{" "}
                      Oncall Team Name or Alias
                    </div>
                  }
                >
                  <Input
                    type="text"
                    name={IDENTITY}
                    value={this.state.identity}
                    placeholder="TeamName or Alias used for oncall"
                    onChange={({ detail }) =>
                      this.handleInputChange(IDENTITY, detail.value)
                    }
                    disabled={this.props.update === true}
                  />
                </FormField>
              </div>
              <div className="inline-input half-width-input">
                <FormField
                  label="Oncall Identity Type"
                  hintText="teamName is most commonly used"
                  errorText={this.state.errorText["identityType"]}
                >
                  <Select
                    triggerVariant="option"
                    options={identityTypes}
                    name="identityType"
                    id="identityType"
                    onChange={({ detail }) =>
                      this.handleSelectMenu(
                        "identityType",
                        detail.selectedOption.id
                      )
                    }
                    selectedOption={
                      identityTypes.filter(
                        type => type.id === this.state.identityType
                      )[0]
                    }
                    disabled={this.props.teamInfo !== undefined}
                  />
                </FormField>
              </div>
            </div>
            <br />
            <div>
              <div className="inline-input half-width-input">
                <FormField label="Product Group">
                  <Select
                    triggerVariant="option"
                    options={productGroupOptions}
                    name={PRODUCT_GROUP}
                    id={PRODUCT_GROUP}
                    onChange={({ detail }) =>
                      this.handleSelectMenu(
                        "identityType",
                        detail.selectedOption.id
                      )
                    }
                    selectedOption={
                      productGroupOptions.filter(
                        type => type.id === this.state.productGroup
                      )[0]
                    }
                    disabled={true}
                  />
                </FormField>
              </div>
              <div className="inline-input half-width-input">
                <FormField label="Pillar" errorText={this.state.errorText[ORG]}>
                  <Select
                    triggerVariant="option"
                    options={orgs["AWS Commerce Platform"]}
                    name={ORG}
                    id={ORG}
                    onChange={({ detail }) =>
                      this.handleSelectMenu(ORG, detail.selectedOption.id)
                    }
                    selectedOption={
                      orgs["AWS Commerce Platform"].filter(
                        type => type.id === this.state.org
                      )[0]
                    }
                    invalid={this.state.orgInvalid}
                  />
                </FormField>
              </div>
            </div>
            <br />
            <div>
              <div className="inline-input half-width-input">
                <FormField
                  label="Is this a secondary oncall group?"
                  errorText={this.state.errorText[IS_SECONDARY]}
                >
                  <Select
                    triggerVariant="option"
                    options={yesNoOptions}
                    name={IS_SECONDARY}
                    id={IS_SECONDARY}
                    onChange={({ detail }) =>
                      this.handleSelectMenu(
                        IS_SECONDARY,
                        detail.selectedOption.id
                      )
                    }
                    selectedOption={
                      yesNoOptions.filter(
                        type => type.id === this.state.isSecondary
                      )[0]
                    }
                  />
                </FormField>
              </div>
              <div className="inline-input half-width-input">
                <FormField
                  label="Does your team use SIM or TT?"
                  errorText={this.state.errorText[SIM]}
                >
                  <Select
                    triggerVariant="option"
                    options={simTTOptions}
                    name={SIM}
                    id={SIM}
                    onChange={({ detail }) =>
                      this.handleSelectMenu(SIM, detail.selectedOption.id)
                    }
                    selectedOption={
                      simTTOptions.filter(type => type.id === this.state.sim)[0]
                    }
                  />
                </FormField>
              </div>
            </div>
            {this.state.isSecondary === "yes" && (
              <div className="inline-input half-width-input">
                <FormField
                  label="Primary Oncall teamName/alias"
                  errorText={this.state.errorText[PRIMARY_ONCALL_ALIAS]}
                >
                  <Input
                    type="text"
                    name={PRIMARY_ONCALL_ALIAS}
                    value={this.state.primaryOncallAlias}
                    placeholder="Primary Oncall teamName/alias"
                    onChange={({ detail }) =>
                      this.handleInputChange(PRIMARY_ONCALL_ALIAS, detail.value)
                    }
                  />
                </FormField>
              </div>
            )}
            <br />
            <br />
            <div className="textarea-container">
              <FormField
                label="Services"
                errorText={this.state.errorText[SERVICES]}
              >
                <Textarea
                  type="text"
                  placeholder="Services your team owns"
                  name={SERVICES}
                  value={this.state.services}
                  onChange={({ detail }) =>
                    this.handleInputChange(SERVICES, detail.value)
                  }
                />
              </FormField>
            </div>
            <br />
            <div>
              <div className="inline-input quarter-width-input">
                <FormField
                  label="Category"
                  errorText={this.state.errorText[CATEGORY]}
                >
                  <Input
                    type="text"
                    name={CATEGORY}
                    value={this.state.category}
                    placeholder="CTI Category"
                    onChange={({ detail }) =>
                      this.handleInputChange(CATEGORY, detail.value)
                    }
                  />
                </FormField>
              </div>
              <div className="inline-input quarter-width-input">
                <FormField label="Type" errorText={this.state.errorText[TYPE]}>
                  <Input
                    type="text"
                    name={TYPE}
                    value={this.state.type}
                    placeholder="CTI Type"
                    onChange={({ detail }) =>
                      this.handleInputChange(TYPE, detail.value)
                    }
                  />
                </FormField>
              </div>
              <div className="inline-input quarter-width-input">
                <FormField label="Item" errorText={this.state.errorText[ITEM]}>
                  <Input
                    type="text"
                    name={ITEM}
                    value={this.state.item}
                    placeholder="CTI Item"
                    onChange={({ detail }) =>
                      this.handleInputChange(ITEM, detail.value)
                    }
                  />
                </FormField>
              </div>
              <div className="inline-input quarter-width-input">
                <FormField
                  label="Resolver Group"
                  errorText={this.state.errorText[RESOLVER_GROUP]}
                >
                  <Input
                    type="text"
                    name={RESOLVER_GROUP}
                    value={this.state.resolverGroup}
                    placeholder="Resolver/Assigned Group"
                    onChange={({ detail }) =>
                      this.handleInputChange(RESOLVER_GROUP, detail.value)
                    }
                  />
                </FormField>
              </div>
            </div>
            <br />
          </div>
        </Modal>
        <DeleteConfirmationModal
          identity={this.state.identity}
          identityType={this.state.identityType}
          show={this.state.showDeleteModal}
          onDeleteHandler={this.handleDeleteTeam}
          onDismiss={this.handleDeleteModal}
        />
        <Modal
          visible={this.state.showInfoImageModal}
          onDismiss={this.handleInfoImageModal}
          size="large"
          expandToFit={true}
        >
          <div className="hint-image-modal">
            <img
              className="hint-image"
              src={teamInfoImage}
              alt="Oncall team name hint"
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  applicationState: state.oncallDataReducer
});

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOncallDataRequest,
      deleteOncallTeamRequest
    },
    dispatch
  );

export default connect(mapStateToProps, matchDispatchToProps)(TeamInfoModal);
