import * as React from "react";
import { Modal, Button } from "@amzn/awsui-components-react";

import "../styles/teamInfoModal.css";

class DeleteConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmitDelete = this.handleSubmitDelete.bind(this);
  }

  handleSubmitDelete() {
    const request = {
      identity: this.props.identity,
      identityType: this.props.identityType
    };
    return this.props.onDeleteHandler(request);
  }

  render() {
    return (
      <div className="awsui input-container">
        <Modal
          header="Delete Team"
          visible={this.props.show}
          size="medium"
          expandToFit={true}
          onDismiss={this.props.onDismiss}
          footer={
            <div>
              <Button onClick={this.handleSubmitDelete} variant="primary">
                Delete
              </Button>
              <Button onClick={this.props.onDismiss} variant="normal">
                Cancel
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to delete your team from the oncall tool?</p>
        </Modal>
      </div>
    );
  }
}

export default DeleteConfirmationModal;
