import MidwayIdentityJavaScriptClient from "@amzn/midway-identity-javascript-client";
import config from "../data/configOrigin";
const clientMap = {};

export function makeClient(config) {
  console.log(
    `Making api client for the endpoint ${config.oncallToolServiceCognitoIdentityPoolId}`
  );

  const client = new MidwayIdentityJavaScriptClient({
    httpEndpoint: config.endpoint,
    cognitoIdentityPoolId: config.oncallToolServiceCognitoIdentityPoolId,
    region: config.region
  });
  return client;
}

export function getAPIClient() {
  if (clientMap[`${config.stage}-${config.region}`]) {
    return clientMap[`${config.stage}-${config.region}`];
  } else {
    const client = makeClient(config);
    clientMap[`${config.stage}-${config.region}`] = client;
    return client;
  }
}
