import {
  GET_ONCALL_TEAMS,
  SET_ALIAS,
  DELETE_TEAM,
  CREATE_TEAM,
  UPDATE_TEAM,
  ERR_CATCH
} from "../actions/types";
import { MISC, orgGroups } from "../data/constData";

const defaultProductGroup = "AWS Commerce Platform";

const initialState = {
  selectedProductGroup: defaultProductGroup,
  fullDataFromAPI: [],
  oncallDataToDisplay: [],
  secondaries: null,
  errInfo: null,
  responseStatus: null,
  requester: null,
  timeStamp: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  let secondaries = {};
  const productMap = new Map();
  const time = new Date();
  let filteredTeams;
  switch (type) {
    case GET_ONCALL_TEAMS:
      return {
        ...state,
        responseStatus: payload.status,
        oncallDataToDisplay: filteredTeamData(payload, state, productMap),
        secondaries: filteredSecondaries(payload, state, secondaries),
        timeStamp: time,
        fullDataFromAPI: payload
      };
    case UPDATE_TEAM:
      filteredTeams = state.oncallDataToDisplay.map(org => {
        return org.map(team => {
          if (
            team.identity === payload.identity &&
            team.identityType === payload.identityType
          ) {
            return payload;
          } else {
            return team;
          }
        });
      });
      return {
        ...state,
        responseStatus: payload.status,
        oncallDataToDisplay: filteredTeams
      };
    case DELETE_TEAM:
      filteredTeams = state.oncallDataToDisplay.map(org => {
        return org.filter(team => {
          return team.identity !== payload.identity;
        });
      });
      return {
        ...state,
        responseStatus: payload.status,
        oncallDataToDisplay: filteredTeams
      };
    case CREATE_TEAM:
      return {
        ...state,
        responseStatus: payload.status
      };
    case ERR_CATCH:
      return {
        ...state,
        errInfo: payload
      };
    case SET_ALIAS:
      return { ...state, requester: payload };
    default:
      return state;
  }
}

function filteredTeamData(payload, prevState, productMap) {
  if (payload.teams) {
    payload.teams.forEach(team => {
      if (
        !team.secondary &&
        team.productGroup === prevState.selectedProductGroup &&
        (team.currOncalls || team.nextOncalls)
      ) {
        let teamOrg = team.org;
        if (!orgGroups[prevState.selectedProductGroup].includes(teamOrg)) {
          teamOrg = MISC;
        }
        if (!productMap[teamOrg]) {
          productMap[teamOrg] = [];
        }
        productMap[teamOrg].push(team);
      }
    });
  }
  return orgGroups[prevState.selectedProductGroup].map(org => {
    return productMap[org] ? productMap[org] : [];
  });
}

function filteredSecondaries(payload, prevState, secondaries) {
  if (payload.teams) {
    payload.teams.forEach(team => {
      if (
        team.productGroup === prevState.selectedProductGroup &&
        team.isSecondary === true &&
        team.currOncalls
      ) {
        secondaries[team.primaryOncallAlias] = team;
      }
    });
  }
  return secondaries;
}
