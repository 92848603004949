import { AWSCPAtlasOncallToolServiceClient } from "../clients/OncallToolServiceClient.js";

const getOncallTeams = async optionalTeamQualifier => {
  const response = await AWSCPAtlasOncallToolServiceClient.getOncallTeams({
    limit: 100
  }).toPromise();
  return response;
};

const postTeam = async request => {
  const response = await AWSCPAtlasOncallToolServiceClient.postTeam({
    ...request
  }).toPromise();
  return response;
};

const deleteTeamOncall = async request => {
  const response = await AWSCPAtlasOncallToolServiceClient.deleteTeamOncall({
    ...request
  }).toPromise();
  return response;
};
const postTeamInfo = async request => {
  const response = await AWSCPAtlasOncallToolServiceClient.postTeamInfo({
    ...request
  }).toPromise();
  return response;
};
const deleteTeamInfo = async request => {
  const response = await AWSCPAtlasOncallToolServiceClient.deleteTeamInfo({
    ...request
  }).toPromise();
  return response;
};

const sendPage = async request => {
  const response = await AWSCPAtlasOncallToolServiceClient.sendPage({
    ...request
  }).toPromise();
  return response;
};

const OncallToolServiceProxy = {
  getOncallTeams,
  postTeam,
  deleteTeamOncall,
  postTeamInfo,
  deleteTeamInfo,
  sendPage
};

export default OncallToolServiceProxy;
