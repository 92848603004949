import * as React from "react";

import {
  Modal,
  Button,
} from "@amzn/awsui-components-react";

import "../styles/teamInfoModal.css";

const ConfirmationModal = (props) => {

  return (
    <div className="awsui input-container">
      <Modal
        header={
          props.update
            ? "Your team data has been updated!"
            : "Your team has been added!"
        }
        visible={props.show}
        size="medium"
        onDismiss={props.onClickHandler}
        footer={
          <div>
            <Button
              onClick={props.onClickHandler}
              variant="primary"
            >
              Got it!
            </Button>
          </div>
        }
      >
        <p>New team data can take up to 5 minutes to show in the browser.</p>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
